

































































































import QualityAnalyticsComponent from '@/components/analyticsComponent/qualityAnalyticsComponent/QualityAnalyticsComponent';
export default QualityAnalyticsComponent;
